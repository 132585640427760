import momemt from 'moment';

export function formatDate(date: Date, dateFormat: string): string {
  // Convert format to moment formatting (https://devhints.io/moment)

  const momentFormat = dateFormat
    .replace("DDDD", "dddd") // Weekday name (Monday, Tuesday, etc.)
    .replace("DDD", "Do");   // Ordinal Month

  return momemt(date).format(momentFormat);
}

export function formatTime(date: Date, timeFormat: string): string {
  // Convert format to moment formatting (https://devhints.io/moment)

  const momentFormat = timeFormat
    .replace("AA", "A")  // AM/PM
    .replace("aa", "a"); // am/pm

  return momemt(date).format(momentFormat);
}
