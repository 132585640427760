import React from "react";
import { PDFDocumentProxy } from "pdfjs-dist";
import { SignatureRequest, SignableDocument, GlobalState } from "./types";
import { GlobalStateAction } from "./reducers";

interface DocumentContextData {
  signatureRequest?: SignatureRequest;
  signableDocument?: SignableDocument;
  pdfDocument?: PDFDocumentProxy;
}

export const DocumentContext = React.createContext<DocumentContextData>({});

interface ModalContextData {
  openModal: () => void;
  closeModal: () => void;
  setRecipientModal: (n: number) => void;
}

export const ModalContext = React.createContext<ModalContextData>({
  openModal: () => undefined,
  closeModal: () => undefined,
  setRecipientModal: (n: number) => undefined
});

interface GlobalStateContextData {
  state: GlobalState;
  dispatch: React.Dispatch<GlobalStateAction>;
}

export const GlobalStateContext = React.createContext<GlobalStateContextData>({
  state: {} as GlobalState,
  dispatch: () => { }
});


export const SignatureRequestContext = React.createContext<SignatureRequest>({} as SignatureRequest);
