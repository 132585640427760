import { useEffect, useState } from "react";

export function useWindowProperties() {
  const [windowProperties, setWindowProperties] = useState(getWindowProps());

  function getWindowProps() {
    const pixelRatio = Math.max(window?.devicePixelRatio || 1, 1);
    const width = window?.innerWidth
    const height = window?.innerHeight

    return { pixelRatio, width, height };
  }

  useEffect(() => {
    function handleResize() {
      setWindowProperties(prev => ({
        ...prev,
        width: window.innerWidth,
        height: window.innerHeight
      }));
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize)
  }, []);

  return windowProperties;
}