import { CheckCircleIcon } from "@heroicons/react/outline";
import { SignatureRequest } from "../types";

function Confirmation(props: { signatureRequest?: SignatureRequest }) {

  return (
    <div className="fixed flex flex-col w-full h-full">
      <div className="flex flex-shrink-0 w-full h-24 z-10 bg-green text-white shadow-md">
        <div className="flex justify-center items-center mx-auto w-full max-w-[800px] px-2 lg:px-0">
          <CheckCircleIcon className="w-8 h-8 mr-1" />
          <div className="font-semibold mr-4">Success!</div>
          You've signed your documents.
        </div>
      </div>
      <div className="flex bg-gray h-full overflow-y-scroll">
        <div className="flex flex-col items-center text-center max-w-[800px] m-auto p-10 bg-white rounded shadow">
          {props.signatureRequest?.branding?.logo
            ? <img src={props.signatureRequest?.branding?.logo} className="mb-4" width={160} alt="Brand Logo" />
            : <img src="https://static.portant.co/logo-full-blue.png" className="mb-4" width={160} alt="Portant Logo" />
          }
          <div className="text-darkgray font-semibold">
            Thanks for signing your document
          </div>
          <div className="text-darkgray font-semibold">
            You will receive a copy in your inbox once all signatures are complete
          </div>
          <div className="my-10 bg-gray w-3/4 h-[2px]" />
          {props.signatureRequest?.branding?.logo &&
            <div className="flex items-center text-black gap-1 text-xs cursor-pointer -mt-5 mb-5" onClick={() => window.open("https://www.portant.co", "_blank")}>
              Powered by
              <img src="https://static.portant.co/logo-full.png" width={80} alt="Portant Logo" />
            </div>
          }
          <div className="text-gray">
            Looking to automate your document signing process?
          </div>
          <div className="text-gray mb-4">
            Activate your free account with Portant
          </div>
          <a href="https://www.portant.co" className="btn btn-primary">
            Visit Portant
          </a>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
