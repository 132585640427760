import { GlobalState, GlobalStateStatus } from "./types";

export enum GlobalStateActionType {
  RESET,
  SET_STATUS,
  INSERT_SIGNATURE,
  UPDATE_FIELD_VALUE,
};

export type GlobalStateAction =
  | { type: GlobalStateActionType.RESET }
  | { type: GlobalStateActionType.SET_STATUS, status: GlobalStateStatus }
  | { type: GlobalStateActionType.INSERT_SIGNATURE, payload: { signatureData: string, signatureDateString: string }; }
  | { type: GlobalStateActionType.UPDATE_FIELD_VALUE, fieldId: string, textValue: string }


export function GlobalStateReducer(state: GlobalState, action: GlobalStateAction): GlobalState {
  switch (action.type) {
    case GlobalStateActionType.RESET:
      return { status: "INITIAL", fieldValues: {} };
    case GlobalStateActionType.SET_STATUS:
      return { ...state, status: action.status };
    case GlobalStateActionType.INSERT_SIGNATURE:
      return { ...state, ...action.payload, status: "SIGNED" };
    case GlobalStateActionType.UPDATE_FIELD_VALUE:
      return { ...state, fieldValues: { ...state.fieldValues, [action.fieldId]: action.textValue } };
    default:
      return state;
  }
}
