import { useContext, useState } from "react";
import { SignatureRequestContext } from "../contexts";
import { luminance } from "../utils";

type RequestState = "PENDING" | "REQUESTING" | "REQUESTED" | "ERROR";

function ManageRequestsModal(props: { visible: boolean, closeModal: () => void, recipientNumber: number }) {
  const { allRecipientsMap, branding, publicToken } = useContext(SignatureRequestContext);

  const [requestState, setRequestState] = useState<RequestState>("PENDING");
  const recipient = allRecipientsMap?.[props.recipientNumber];

  if (!props.visible || !recipient)
    return null;

  function handleSendRequest() {
    setRequestState("REQUESTING");

    const paylaod = JSON.stringify({ recipientNumber: props.recipientNumber });

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${publicToken}/send-link/`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: paylaod
    })
      .then((res) => setRequestState(res.status === 204 ? "REQUESTED" : "ERROR"))
      .catch(() => setRequestState("ERROR"))
  }

  return (
    <div className="flex absolute top-0 left-0 w-full h-full z-50 backdrop-filter backdrop-blur-sm">
      <div className="flex flex-col relative mt-auto lg:m-auto p-8 w-full lg:max-w-[600px] lg:rounded shadow-lg bg-white">
        <div className="relative text-lg font-semibold mb-4">
          Request Signing Link
        </div>
        {requestState !== "REQUESTED" && (
          <>
            <div>
              Send <span className="font-semibold">{recipient.email}</span> a unique signing link to add their signature to the document.
            </div>
            {requestState === "ERROR" &&
              <div className="text-red font-semibold mt-2">
                An error occured, please try again or contact support.
              </div>
            }
            <div className="flex justify-between items-center mt-4 w-full">
              <button className="btn btn-secondary w-40" onClick={() => { props.closeModal() }} disabled={requestState === "REQUESTING"}>
                Close
              </button>
              <button className="btn btn-primary w-40" onClick={handleSendRequest} style={{ backgroundColor: branding?.colour, color: luminance(branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }} disabled={requestState === "REQUESTING"}>
                {requestState === "REQUESTING" ? "Sending..." : "Send"}
              </button>
            </div>
          </>
        )}
        {requestState === "REQUESTED" && (
          <>
            <div>
              <span className="font-semibold text-green">Success!</span> a unique signing link has been sent to <span className="font-semibold">{recipient.email}</span>
            </div>
            <div className="flex justify-between items-center mt-4 w-full">
              <button className="btn btn-primary ml-auto" onClick={() => window.location.reload()} style={{ backgroundColor: branding?.colour, color: luminance(branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }}>
                Close and Refresh
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ManageRequestsModal;