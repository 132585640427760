import { PencilAltIcon, UploadIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useRef, useState } from "react";
import { ModalContext, GlobalStateContext, SignatureRequestContext } from "../contexts";
import { GlobalStateActionType } from "../reducers";
import SignatureField, { SignatreFieldRef } from "./SignatureField";

import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { formatDate, formatTime } from "../formatting";
import { luminance } from "../utils";

function SignatureModal(props: { visible: boolean }) {
  const { closeModal } = useContext(ModalContext);
  const { dispatch } = useContext(GlobalStateContext);

  const { branding, formatting } = useContext(SignatureRequestContext);

  const [inputMode, setInputMode] = useState<"draw" | "type" | "upload">("draw");

  const [signatureHasDrawn, setSignatureHasDrawn] = useState(false);
  const [signatureText, setSignatureText] = useState<string>("");
  const [signatureUpload, setSignatureUpload] = useState<string>("");

  const signatureField = useRef<SignatreFieldRef>(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  function handleInsert() {
    const now = new Date();

    const timeString = formatTime(now, formatting?.dateTimeFormat ?? "12_HOUR");
    const dateString = (formatDate(now, formatting?.dateFormat ?? "MM/DD/YYYY") + " " + timeString).trim();

    if (inputMode === "type" || inputMode === "draw")
      dispatch({
        type: GlobalStateActionType.INSERT_SIGNATURE,
        payload: {
          signatureData: signatureField.current!.getSignatureData(),
          signatureDateString: dateString
        }
      });

    if (inputMode === "upload")
      dispatch({
        type: GlobalStateActionType.INSERT_SIGNATURE,
        payload: {
          signatureData: cropperRef.current!.cropper.getCroppedCanvas().toDataURL(),
          signatureDateString: dateString
        }
      });

    closeModal();
  }

  function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files?.length !== 1)
      return;

    const file = event.currentTarget.files[0];
    setSignatureUpload(URL.createObjectURL(file));
  }

  const isDisabled = (() => {
    if (inputMode === "draw")
      return !signatureHasDrawn;

    if (inputMode === "type")
      return !signatureText;

    if (inputMode === "upload")
      return !signatureUpload;
  })();

  if (!props.visible)
    return null;

  return (
    <div className="flex absolute top-0 left-0 w-full h-full z-50 backdrop-filter backdrop-blur-sm">
      <div className="flex flex-col relative mx-auto mt-auto lg:my-auto px-4 lg:px-8 py-4 w-full max-w-[600px] bg-white border border-gray rounded shadow-lg overflow-hidden">
        <XIcon className="w-4 h-4 absolute top-4 right-4 text-gray hover:text-red cursor-pointer" onClick={closeModal} />
        <div className="font-title font-semibold mb-4">
          Add your signature
        </div>
        <div className="flex gap-8 mb-1 text-sm">
          <div className={"flex items-center border-b-2 cursor-pointer " + (inputMode === "draw" ? "text-blue border-blue" : "border-white")} onClick={() => setInputMode("draw")}>
            <PencilAltIcon className="w-4 h-4 mr-2" /> Draw it
          </div>
          <div className={"flex items-center border-b-2 cursor-pointer " + (inputMode === "type" ? "text-blue border-blue" : "border-white")} onClick={() => setInputMode("type")}>
            <span className="font-title text-base mr-2">Aa</span> Type it
          </div>
          <div className={"flex items-center border-b-2 cursor-pointer " + (inputMode === "upload" ? "text-blue border-blue" : "border-white")} onClick={() => setInputMode("upload")}>
            <UploadIcon className="w-4 h-4 mr-2" /> Upload
          </div>
        </div>
        {(inputMode === "draw" || inputMode === "type") &&
          <div className="relative mx-auto flex-shrink-0 w-full">
            <SignatureField ref={signatureField} className="shadow-inner" text={signatureText} renderText={inputMode === "type"} setHasChanges={setSignatureHasDrawn} />
            {inputMode === "type" &&
              <input type="text" className="absolute top-2 left-2 right-2 px-2 py-1 outline-none border-gray border rounded shadow" value={signatureText} onChange={e => setSignatureText(e.currentTarget.value)} placeholder="Full Name" autoFocus />
            }
          </div>
        }
        {inputMode === "upload" &&
          <div className="w-full flex flex-col gap-2 items-center justify-center max-w-full">
            {signatureUpload &&
              <div>
                <Cropper
                  ref={cropperRef}
                  src={signatureUpload}
                  style={{ height: "260px", width: "540px", marginTop: 10 }}
                  aspectRatio={269 / 67}
                  autoCropArea={1.0}
                />
              </div>
            }
            <input type="file" accept="image/png, image/jpeg" onChange={handleUpload} style={signatureUpload ? { paddingTop: 10 } : { paddingTop: 52, paddingBottom: 51.5 }} />
          </div>
        }
        <div className="flex justify-between items-center mt-4">
          <div className="text-xs text-gray">
            I understand that this is a legal representation of my signature
          </div>
          <button className="btn btn-primary w-40" onClick={handleInsert} style={{ backgroundColor: branding?.colour, color: luminance(branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }} disabled={isDisabled}>
            Insert
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignatureModal;