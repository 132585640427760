function PageNotFound() {
  return (
    <div className="fixed flex w-full h-full bg-gray">
      <div className="flex flex-col items-center m-auto px-2 max-w-[800px] text-center">
        <img src="https://static.portant.co/logo-full-blue.png" className="mb-4 mx-auto" width={160} alt="Portant Logo" />
        <div className="font-title font-semibold text-lg">404 Not Found</div>
        <div className="mb-4">
          The page you're looking for could not be found.
        </div>
        <a href="https://www.portant.co" className="btn btn-primary">
          Take me home
        </a>
      </div>

    </div>
  )
}

export default PageNotFound;