import PageNotFound from "./PageNotFound";
import PageAlreadySigned from "./PageAlreadySigned";
import PageOverlay from "./PageOverlay";
import { useEffect, useReducer, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { PDFDocument } from "pdf-lib";
import { SignatureRequest, SignatureRequestError } from "../types";
import { ChevronDownIcon } from '@heroicons/react/outline'
import { PDFDocumentProxy } from "pdfjs-dist";
import { DocumentContext, ModalContext, GlobalStateContext, SignatureRequestContext } from "../contexts";
import { GlobalStateActionType, GlobalStateReducer } from "../reducers";
import SignatureModal from "./SignatureModal";
import fontkit from '@pdf-lib/fontkit'
import Confirmation from "./Confirmation";
import { useWindowProperties } from "../customHooks";
import ContinueModal from "./ContinueModal";
import AppLanding from "./AppLanding";
import PageCanceled from "./PageCanceled";
import PageExpired from "./PageExpired";
import checkIcon from "../checkIcon";
import ManageRequestsModal from "./ManageRequestsModal";
import PreviewPageOverlay from "./PreviewPageOverlay";
import { luminance } from "../utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function App() {
  const token = window.location.pathname.split("/")[1];

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [signatureRequest, setSignatureRequest] = useState<SignatureRequest>();
  const [error, setError] = useState<SignatureRequestError | undefined>();

  const isPreview = signatureRequest?.recipientNumber === 0;

  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const signableDocument = signatureRequest?.signableDocuments[currentDocumentIndex] ?? null;

  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();

  const [currentRecipientNumber, setCurrentRecipientNumber] = useState<number>(1);

  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);
  const [showContinueModal, setShowContinueModal] = useState<boolean>(false);
  const [showManageRequestsModal, setShowManageRequestsModal] = useState<boolean>(false);

  const [globalState, dispatch] = useReducer(GlobalStateReducer, { status: "INITIAL", fieldValues: {} });

  const windowProperties = useWindowProperties();

  useEffect(() => {
    if (!token)
      return setIsLoading(false);

    setIsLoading(true);
    const abortController = new AbortController();

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${token}/`, { signal: abortController.signal })
      .then(async (res) => {
        setIsLoading(false);

        if (res.ok) return res.json();
        else setError(await res.json());
      })
      .then(setSignatureRequest)
      .catch(error => console.error(error));

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${token}/access/`, { method: "POST", signal: abortController.signal })
      .catch(console.error);

    return () => abortController.abort();
  }, [token]);

  useEffect(() => {
    document.title = signableDocument?.documentName ?? "Portant Sign"
  }, [signableDocument])

  async function generateSignedPdf() {
    if (!globalState.signatureData || !pdfDocument || !signableDocument)
      throw new Error("Unable to generate signed PDF without signature data.");

    const data = await pdfDocument.getData();
    const signedPdfDocument = await PDFDocument.load(data);

    const pdfSignatureImage = await signedPdfDocument.embedPng(globalState.signatureData);
    const checkImage = await signedPdfDocument.embedPng(checkIcon);

    signedPdfDocument.registerFontkit(fontkit)
    const fontUrl = process.env.PUBLIC_URL + "/fonts/Eina02-Regular.ttf";
    const fontData = await fetch(fontUrl).then(res => res.arrayBuffer());
    const eina02Font = await signedPdfDocument.embedFont(fontData);

    signableDocument.fieldRegions
      .filter(r => !signatureRequest!.recipientNumber || r.recipient === signatureRequest!.recipientNumber)
      .forEach((region, index) => {
        const page = signedPdfDocument.getPage(region.pageNumber - 1); // -1 for 0-based index
        if (region.type === "SIGNATURE") {
          page.drawImage(pdfSignatureImage, {
            x: region.bbox.x0,
            y: page.getMediaBox().height - region.bbox.y1,
            width: region.bbox.x1 - region.bbox.x0,
            height: region.bbox.y1 - region.bbox.y0
          });

        } else if (region.type === "DATE") {
          const dateString = globalState.signatureDateString!;

          page.drawText(dateString, {
            x: region.bbox.x0,
            y: page.getMediaBox().height - region.bbox.y1,
            size: 12,
            font: eina02Font
          });

        } else if (region.type === "TEXT") {
          const text = globalState.fieldValues[region.id] ?? "";

          page.drawText(text, {
            x: region.bbox.x0,
            y: page.getMediaBox().height - region.bbox.y1 + 12 - 4, // font size - padding
            size: 12,
            font: eina02Font,
          });

        } else if (region.type === "CHECKBOX") {
          const checked = !!globalState.fieldValues[region.id];
          if (!checked)
            return

          page.drawImage(checkImage, {
            x: region.bbox.x0 + 2,
            y: page.getMediaBox().height - region.bbox.y1 + 2,
            width: region.bbox.x1 - region.bbox.x0 - 4,
            height: region.bbox.y1 - region.bbox.y0 - 4
          });
        }
      });

    const signedPdfData = await signedPdfDocument.save();
    return signedPdfData;
  }

  // NOTE: Currently unused
  // async function handleDownload() {
  //   if (!pdfDocument)
  //     return;

  //   let data;
  //   if (globalState.status === "SIGNED")
  //     data = await generateSignedPdf();
  //   else
  //     data = await pdfDocument.getData() as Uint8Array;

  //   const blob = new Blob([data], { type: "application/pdf" });
  //   const downloadUrl = window.URL.createObjectURL(blob);

  //   const link = window.document.createElement("a");
  //   link.href = downloadUrl;
  //   link.download = signableDocument!.documentName.replaceAll(".", "_");
  //   link.click();
  // }

  function handleClickEdit() {
    dispatch({ type: GlobalStateActionType.RESET });
    setShowSignatureModal(true);
  }

  async function handleClickAgree() {
    dispatch({ type: GlobalStateActionType.SET_STATUS, status: "SUBMITTING" });

    const signedPdfData = await generateSignedPdf();

    const uploadUrlData = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${signatureRequest!.publicToken}/generate-upload-url/`, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        signableDocumentId: signableDocument!.id
      })
    }).then(res => res.json());

    const uploadUrl = uploadUrlData["url"];
    const uploadResponse = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        "content-type": "application/pdf"
      },
      body: signedPdfData
    });

    const nextDocumentIndex = currentDocumentIndex + 1;

    if (nextDocumentIndex === signatureRequest!.signableDocuments.length) {
      const status = uploadResponse.status === 200 ? "SUCCESS" : "ERROR";
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${signatureRequest!.publicToken}/confirm-upload-status/`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ signableDocumentId: signableDocument!.id, status })
      });

      dispatch({ type: GlobalStateActionType.SET_STATUS, status: "SUBMITTED" });

      if (signatureRequest!.redirectUrl)
        window.location.href = signatureRequest!.redirectUrl;
    } else {
      setCurrentDocumentIndex(nextDocumentIndex);
      dispatch({ type: GlobalStateActionType.RESET });
    }
  }

  if (isLoading || (globalState.status === "SUBMITTED" && signatureRequest?.redirectUrl))
    return (
      <div className="fixed flex w-full h-full bg-gray">
        <img src="https://static.portant.co/portant-loading-blue.svg" className="w-32 h-32 m-auto" alt="Loading Logo" />
      </div>
    );

  if (!token)
    return <AppLanding />

  if (error && error.detail === "The signature request has expired.")
    return <PageExpired />

  if (!signatureRequest)
    return <PageNotFound />

  if (signatureRequest?.status === "CANCELED" || signableDocument === null)
    return <PageCanceled />

  if (signatureRequest?.status === "COMPLETED")
    return <PageAlreadySigned />

  if (globalState.status === "SUBMITTED")
    return <Confirmation signatureRequest={signatureRequest} />

  const pageWidth = Math.min(800, windowProperties.width);
  const pageNumbers: Array<number> = Array(pdfDocument?.numPages ?? 0).fill(1).map((one: number, i: number) => one + i);

  return (
    <SignatureRequestContext.Provider value={signatureRequest}>
      <ModalContext.Provider value={{ openModal: () => isPreview ? setShowManageRequestsModal(true) : setShowSignatureModal(true), closeModal: () => isPreview ? setShowManageRequestsModal(false) : setShowSignatureModal(false), setRecipientModal: (n) => setCurrentRecipientNumber(n) }}>
        <GlobalStateContext.Provider value={{ state: globalState, dispatch }}>
          <div className="fixed flex flex-col w-full h-full">
            <SignatureModal visible={showSignatureModal} />
            <ContinueModal visible={showContinueModal} closeModal={() => setShowContinueModal(false)} onContinue={handleClickAgree} />
            <ManageRequestsModal visible={showManageRequestsModal} closeModal={() => setShowManageRequestsModal(false)} recipientNumber={currentRecipientNumber} />
            <div className="relative flex flex-shrink-0 w-full h-24 z-10 bg-blue text-white mix-blend-difference shadow-md" style={{ backgroundColor: signatureRequest.branding?.colour, color: luminance(signatureRequest.branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }}>
              {(signatureRequest.branding?.logo && globalState.status === "INITIAL") &&
                <div className="left-10 absolute top-5 hidden lg:block">
                  <img src={signatureRequest.branding?.logo} className="h-12" alt="Brand Logo" />
                </div>
              }
              <div className="flex items-center justify-between mx-auto w-full lg:w-[800px] px-4 lg:px-0">
                {globalState.status === "INITIAL" && <>
                  {/* Remove download button before signing */}
                  {/* <DownloadIcon className="w-8 h-8 cursor-pointer flex-shrink-0" onClick={handleDownload} /> */}
                  {(signatureRequest.signableDocuments.length === 1 || signatureRequest.recipientNumber !== 0)
                    ? <div className="ml-4 mr-auto font-semibold font-title w-full md:w-[500px] truncate">{signableDocument.documentName}</div>
                    : (
                      <div className="relative flex items-center border-2 border-white rounded w-full md:w-[500px] ml-4 mr-auto">
                        <select className="font-semibold font-title text-white bg-blue text-sm py-1 border-0 truncate outline-none appearance-none w-full ml-2 pr-10" onChange={(e) => setCurrentDocumentIndex(parseInt(e.currentTarget.value))}>
                          {signatureRequest.signableDocuments.map((doc, index) => (
                            <option key={index} value={index}>
                              {doc.documentName}
                            </option>
                          ))}
                        </select>
                        <ChevronDownIcon className="absolute right-0 flex-shrink-0 px-2 h-6 text-white bg-blue pointer-events-none" />
                      </div>
                    )}

                  <div className="hidden lg:flex flex-col items-center flex-shrink-0 ml-4">
                    {signatureRequest.recipientNumber >= 1 &&
                      <button className="btn btn-secondary mb-2 w-52" onClick={() => setShowSignatureModal(true)} disabled={isPreview}>
                        {currentDocumentIndex === 0 ? "Sign Now" : "Continue"}
                      </button>
                    }
                    <div className="flex items-center gap-1 text-xs cursor-pointer flex-shrink-0" onClick={() => window.open("https://www.portant.co", "_blank")}>
                      Powered by
                      {luminance(signatureRequest.branding?.colour ?? "#000000") >= 0.5
                        ? <img src="https://static.portant.co/logo-full.png" width={80} alt="Portant Logo" />
                        : <img src="https://static.portant.co/logo-full-white.png" width={80} alt="Portant Logo" />
                      }
                    </div>
                  </div>
                </>}
                {(globalState.status === "SIGNED") && <>
                  {/* <DownloadIcon className="hidden lg:block w-8 h-8 cursor-pointer flex-shrink-0" onClick={handleDownload} /> */}
                  <div className="text-sm flex-shrink mx-2">
                    <span className="font-semibold">Almost there!</span> I agree to be legally bound by the document and the <a className="underline" href="https://www.portant.co/terms-and-conditions" target="_blank" rel="noreferrer">Portant Terms of Service</a>. Click on 'I Agree' to submit this document. <button className="inline text-sm underline" onClick={handleClickEdit}>Edit</button>
                  </div>
                  <div className="hidden lg:flex flex-col items-center">
                    <button className="btn btn-secondary mb-2 w-52" onClick={handleClickAgree}>
                      I Agree
                    </button>
                    {!signatureRequest.branding &&
                      <div className="flex items-center text-white gap-1 text-xs cursor-pointer" onClick={() => window.open("https://www.portant.co", "_blank")}>
                        Powered by
                        <img src="https://static.portant.co/logo-full-white.png" width={80} alt="Portant Logo" />
                      </div>
                    }
                  </div>
                </>}
                {globalState.status === "SUBMITTING" && <>
                  <div className="mx-auto">
                    <span className="font-semibold">Submitting your document...</span>
                  </div>
                </>}
              </div>
            </div>
            <div className="flex flex-col h-full bg-gray overflow-y-scroll py-4">
              <DocumentContext.Provider value={{ signatureRequest, signableDocument, pdfDocument }}>
                <Document file={signableDocument!.documentUrl} onLoadSuccess={setPdfDocument} className="mx-auto" loading={<div className="opacity-0" />}>
                  {pageNumbers.map((n: number) =>
                    <div key={n} className="relative mb-4">
                      <Page pageNumber={n} width={pageWidth} className="rounded shadow overflow-hidden" renderTextLayer={false} renderAnnotationLayer={false} loading={<div className="opacity-0" />} />
                      {signatureRequest?.recipientNumber === 0
                        ? <PreviewPageOverlay key={n} pageNumber={n} width={pageWidth} />
                        : <PageOverlay key={n} pageNumber={n} width={pageWidth} />
                      }
                    </div>
                  )}
                </Document>
              </DocumentContext.Provider>
            </div>
            {globalState.status === "INITIAL" &&
              <div className="flex gap-4 px-4 lg:hidden items-center justify-center flex-shrink-0 w-full z-10 bg-blue text-white py-4 shadow-md" style={{ backgroundColor: signatureRequest.branding?.colour, color: luminance(signatureRequest.branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }}>
                {(signatureRequest.branding?.logo && globalState.status === "INITIAL") &&
                  <img src={signatureRequest.branding?.logo} className="h-12 mr-auto" alt="Brand Logo" />
                }
                <div className="flex flex-col items-center">
                  {signatureRequest.recipientNumber >= 1 &&
                    <button className="btn btn-secondary w-52 mb-1" onClick={() => setShowSignatureModal(true)} disabled={isPreview}>
                      {currentDocumentIndex === 0 ? "Sign Now" : "Continue"}
                    </button>
                  }
                  <div className="flex items-center gap-1 text-xs cursor-pointer -mb-1" onClick={() => window.open("https://www.portant.co", "_blank")}>
                    Powered by
                    {luminance(signatureRequest.branding?.colour ?? "#000000") >= 0.5
                      ? <img src="https://static.portant.co/logo-full.png" width={80} alt="Portant Logo" />
                      : <img src="https://static.portant.co/logo-full-white.png" width={80} alt="Portant Logo" />
                    }
                  </div>
                </div>
              </div>
            }
            {globalState.status === "SIGNED" &&
              <div className="flex lg:hidden flex-col items-center flex-shrink-0 w-full z-10 bg-blue text-white py-4 shadow-md" style={{ backgroundColor: signatureRequest.branding?.colour, color: luminance(signatureRequest.branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }}>
                <button className="btn btn-secondary w-52" onClick={handleClickAgree}>
                  I Agree
                </button>
              </div>
            }
          </div>
        </GlobalStateContext.Provider>
      </ModalContext.Provider>
    </SignatureRequestContext.Provider>
  );
}

export default App;
